import React, { useState, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Menu from '../../components/Menu/Menu';
import Footer from '../../components/Footer/Footer';
import NewOrderButton from '../../components/NewOrder/NewOrderButton';
import NewOrderModal from '../../components/NewOrder/NewOrderModal';
import PrivacyModal from '../../components/Privacy/PrivacyModal';
import TermsModal from '../../components/Terms/TermsModal';
import SuppliersRow from './SuppliersRow';
import Supplier from './Supplier/Supplier';
import { getSuppliers, getMySuppliers } from '../../services/OrdersService';
import { whoIAm, getSettings } from '../../services/SettingsService';
import Pagination from '../../components/Pagination/Pagination';
import ViewSuppliersModal from './ViewSuppliersModal';
import ViewRespModal from './Supplier/ViewRespModal';
import Toast from '../../components/Toast/Toast';
import useWebSocket from 'react-use-websocket';
import { useTranslation } from 'react-i18next';

function Suppliers() {
    const { t } = useTranslation();

    const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 992);

    useEffect(() => {
        const handleResize = () => {
            setIsLargeScreen(window.innerWidth >= 992);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const defaultLocation = useLocation();
    const id = localStorage.getItem('id');

    function getPage(location) {
        if (!location) location = defaultLocation;
        return new URLSearchParams(location.search).get('page') || 1;
    }

    const history = useHistory();

    useEffect(() => {
        return history.listen((location) => {
            setPage(getPage(location));
        });
    }, [history]);

    const [search, setSearch] = useState('');

    const [settings, setSettings] = useState({});

    const [suppliers, setSuppliers] = useState([]);

    const [notification, setNotification] = useState([]);

    const [count, setCount] = useState(0);

    const [supplier, setSupplier] = useState(false);

    const [viewSupplier, setViewSupplier] = useState({});

    const [page, setPage] = useState(getPage());

    const [isLoading, setIsLoading] = useState(false);

    const [refresh, setRefresh] = useState(0);

    const [isType2Visible, setIsType2Visible] = useState(false);

    const [validateSuppliers, setValidateSuppliers] = useState(false);

    const [myType, setMyType] = useState('');

    const [selectedSupplier, setSelectedSupplier] = useState(null);

    const [viewComments, setViewComments] = useState({});

    const [suppliersWinner, setSuppliersWinner] = useState(false);

    const PAGE_SIZE = 10;

    useEffect(() => {
        getSettings()
            .then(result => setSettings(result))
            .catch(err => console.error(err.response ? err.response.data : err.message));

        whoIAm()
            .then(result => {
                setMyType(result);
                if (result === '3') {
                    setSupplier(true);
                    setIsType2Visible(true);
                    history.push('/mysuppliers/');
                    loadSuppliers(search, page);
                }
            })
            .catch(err => {
                console.error(err.response ? err.response.data : err.message);
                setNotification({ type: 'error', text: err.response ? err.response.data : err.message });
            });
    }, []);

    useEffect(() => {
        const unlisten = history.listen((location) => {
            setPage(getPage(location));
        });
    
        return () => {
            unlisten();
        };
    }, [history]);
    
    useEffect(() => {
        loadSuppliers(search, page);
    }, [page, search, refresh, supplier]);

    const loadSuppliers = (search, page) => {
        setIsLoading(true);
        const offset = (page - 1) * PAGE_SIZE;

        const fetchSuppliers = supplier ? getSuppliers : getMySuppliers;
        
        fetchSuppliers(search)
            .then(result => {
                const paginatedSuppliers = result.rows.slice(offset, offset + PAGE_SIZE);
                setSuppliers(paginatedSuppliers);
                setCount(result.count);
                setViewSupplier(paginatedSuppliers[0]);
                setIsLoading(false);
            })
            .catch(err => {
                console.error(err.response ? err.response.data : err.message);
                setNotification({ type: 'error', text: err.response ? err.response.data : err.message });
                setIsLoading(true);
            });
    };

    /*const countAnalysisOrders = (orders) => {
        return orders.reduce((count, order) => {
          const analysisOrders = order.services
            ? order.services.filter(service => service.active && !service.winner)
            : order.suppliers.filter(supplier => supplier.active && !supplier.winner);
          
          return count + analysisOrders.length;
        }, 0);
    };
    
    const analysisCount = countAnalysisOrders(suppliers);
    const isDisabled = settings.type !== '1' && settings.priority === 0 && analysisCount > 2;*/


    const countAnalysisOrders = (orders, settings) => {
        return orders.reduce((count, order) => {
          const analysisOrders = order.services
            ? order.services.filter(service => {
                //console.log(service);
                return service.active && !service.winner && parseFloat(service.userId) === parseFloat(settings.id);
              })
            : order.suppliers.filter(supplier => supplier.active && !supplier.winner && parseFloat(supplier.userId) === parseFloat(settings.id));
          
          //console.log( analysisOrders.length);
          return count + analysisOrders.length;
        }, 0);
    };

    const analysisCount = countAnalysisOrders(suppliers, settings);
    const isDisabled = useMemo(() => {
        return settings.type !== '1' && settings.priority === 0 && analysisCount > 1;
    }, [settings.type, settings.priority, analysisCount]);

    const { lastJsonMessage } = useWebSocket(process.env.REACT_APP_WS_URL, {
        onOpen: () => {
            console.log(`Connected to App WS`);
        },
        onMessage: (message) => {
            const data = JSON.parse(message.data);
            if (data.type === 'refreshOrders') {
                setRefresh(Date.now());
            }
        },
        queryParams: { 'token': localStorage.getItem("token") },
        onError: (event) => {
            console.error(event);
            setNotification({ type: 'error', text: JSON.stringify(event) });
        },
        shouldReconnect: (closeEvent) => true,
        reconnectInterval: 3000
    });

    function onSearchChange(event) {
        setSearch(event.target.value);
    }

    function onViewSupplier(supplierData) {
        const validate = supplierData.some(item => parseFloat(id) === parseFloat(item.clienteId));
        setValidateSuppliers(validate);
        const winner = supplierData.some(item => item.winner === true);
        setSuppliersWinner(winner);
        setSelectedSupplier(supplierData);
    }

    function handleSupplierClick(id) {
        const supplier = suppliers.find(o => o.id == id);
        setViewSupplier({ ...supplier });
    }

    function onStarClick(event) {
        const id = event.target.id.replace('rating', '');
        const supplier = suppliers.find(o => o.id == id);
        const supplierWithRating = { ...supplier, rating: true };
        setViewSupplier({ ...supplierWithRating });
    }

    function onOrderSubmit(order) {
        setRefresh(Date.now());
        setNotification({ type: 'success', text: t('Solicitação realizada com sucesso!') });
        setSelectedSupplier(null);
    }

    function onAllowOrderSubmit(order) {
        setRefresh(Date.now());
        setSelectedSupplier(null);
        setNotification({ type: 'success', text: t('Proposta aceita com sucesso!') });
    }

    function onDenyOrderSubmit(order) {
        setRefresh(Date.now());
        setSelectedSupplier(null);
        setNotification({ type: 'success', text: t('Proposta rejeitada com sucesso!') });
    }

    function onCancelSubmit(order) {
        setRefresh(Date.now());
        setSelectedSupplier([]);
        setNotification({ type: 'success', text: t('Solicitação cancelada com sucesso!') });
    }

    function onExcludeSubmit(order) {
        setRefresh(Date.now());
        setNotification({ type: 'success', text: t('Solicitação excluída com sucesso!') });
    }

    function onPropostaSubmit(order) {
        setRefresh(Date.now());
        setNotification({ type: 'success', text: t('Proposta realizada com sucesso!') });
    }

    function onFinishSubmit(order) {
        setRefresh(Date.now());
        setNotification({ type: 'success', text: t('Excluído com sucesso!') });
    }

    function showSupplier() {
        return window.location.href.indexOf('mysuppliers') !== -1;
    }

    function toggleMarket() {
        if (showSupplier()) {
            setSupplier(false);
            history.push('/suppliers/');
        } else {
            setSupplier(true);
            history.push('/mysuppliers/');
        }
        setRefresh(Date.now());
    }

    function starSupplier() {
        const order = {
            userId: id,
            rating: true
        };
        setViewComments(order);
    }

    return (
        <React.Fragment>
            <Menu />
            {isLargeScreen ? (
                <main className="content">
                    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                        {isType2Visible ? (
                            <React.Fragment>
                                <div className="d-block mb-4 mb-md-0">
                                    <h2 className="h4">
                                        {showSupplier() ? t('Meus Atendimentos') : t('Fornecedores')}
                                    </h2>
                                </div>
                                <div className="btn-toolbar mb-2 mb-md-0">
                                    <div className="d-inline-flex align-items-center me-2 mb-3">
                                        <button id="starSupplier" className="btn btn-white animate-up-2 me-1" data-bs-toggle="modal" data-bs-target="#modalResp" onClick={starSupplier}>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="icon icon-xs me-0">
                                                <path fillRule="evenodd" d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z" clipRule="evenodd" />
                                            </svg>
                                        </button>
                                        <button
                                            className="btn btn-white animate-up-2 me-1"
                                            onClick={toggleMarket}
                                        >
                                            {showSupplier() ? (
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" strokeWidth="1.0" className="icon icon-xs me-0">
                                                    <path fillRule="evenodd" d="M15.97 2.47a.75.75 0 0 1 1.06 0l4.5 4.5a.75.75 0 0 1 0 1.06l-4.5 4.5a.75.75 0 1 1-1.06-1.06l3.22-3.22H7.5a.75.75 0 0 1 0-1.5h11.69l-3.22-3.22a.75.75 0 0 1 0-1.06Zm-7.94 9a.75.75 0 0 1 0 1.06l-3.22 3.22H16.5a.75.75 0 0 1 0 1.5H4.81l3.22 3.22a.75.75 0 1 1-1.06 1.06l-4.5-4.5a.75.75 0 0 1 0-1.06l4.5-4.5a.75.75 0 0 1 1.06 0Z" clipRule="evenodd" />
                                                </svg>
                                            ) : (
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" strokeWidth="1.0" className="icon icon-xs me-0">
                                                    <path fillRule="evenodd" d="M15.97 2.47a.75.75 0 0 1 1.06 0l4.5 4.5a.75.75 0 0 1 0 1.06l-4.5 4.5a.75.75 0 1 1-1.06-1.06l3.22-3.22H7.5a.75.75 0 0 1 0-1.5h11.69l-3.22-3.22a.75.75 0 0 1 0-1.06Zm-7.94 9a.75.75 0 0 1 0 1.06l-3.22 3.22H16.5a.75.75 0 0 1 0 1.5H4.81l3.22 3.22a.75.75 0 1 1-1.06 1.06l-4.5-4.5a.75.75 0 0 1 0-1.06l4.5-4.5a.75.75 0 0 1 1.06 0Z" clipRule="evenodd" />
                                                </svg>
                                            )}
                                        </button>
                                        <NewOrderButton />
                                    </div>
                                    <div className="btn-group"></div>
                                </div>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <div className="d-block mb-4 mb-md-0">
                                    <h2 className="h4">
                                        {t('Fornecedores')}
                                    </h2>
                                </div>
                                <div className="btn-toolbar mb-2 mb-md-0">
                                    <div className="d-inline-flex align-items-center me-2 mb-3">
                                        <NewOrderButton />
                                    </div>
                                    <div className="btn-group"></div>
                                </div>
                            </React.Fragment>
                        )}
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="card card-body border-0 shadow table-wrapper table-responsive">
                                {selectedSupplier && validateSuppliers && !suppliersWinner ? (
                                    <>
                                        <button
                                            className="btn btn-primary mb-4"
                                            onClick={() => setSelectedSupplier(null)}
                                        >
                                            {t('Voltar')}
                                        </button>
                                        <Supplier suppliers={selectedSupplier} onAllowOrder={onAllowOrderSubmit} onDenyOrder={onDenyOrderSubmit} />
                                    </>
                                ) : (
                                    <table className="table table-hover">
                                        <thead>
                                            <tr>
                                                <th className="border-gray-200">Nº</th>
                                                <th className="border-gray-200">{t('DATA')}</th>
                                                <th className="border-gray-200">{t('CATEGORIA')}</th>
                                                <th className="border-gray-200">{t('ESTADO')}</th>
                                                <th className="border-gray-200">{t('DETALHES')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {!isLoading && suppliers && suppliers.length
                                                ? suppliers.map(item =>
                                                    item.type === '2'
                                                        ? <React.Fragment key={item.id}></React.Fragment>
                                                        : !item.activesf && parseFloat(id) !== parseFloat(item.userId)
                                                            ? <React.Fragment key={item.id}></React.Fragment>
                                                            : parseFloat(id) !== parseFloat(item.userId)
                                                                ? (<SuppliersRow key={item.id} data={item} myType={myType} onClick={() => handleSupplierClick(item.id)} onViewSupplier={onViewSupplier} onStarClick={onStarClick} />)
                                                                : !supplier
                                                                    ? (<SuppliersRow key={item.id} data={item} myType={myType} onClick={() => handleSupplierClick(item.id)} onViewSupplier={onViewSupplier} onStarClick={onStarClick} />)
                                                                    : <React.Fragment></React.Fragment>)
                                                : <tr><td colSpan={6}>{t('Carregando')}...</td></tr>
                                            }
                                        </tbody>
                                    </table>
                                )}
                            </div>
                        </div>
                    </div>
                    <Pagination count={count} size={PAGE_SIZE} />
                    <Footer />
                </main>
            ) : (
                <React.Fragment>
                    <main className="content">
                        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                            <div className="d-block mb-4 mb-md-0">
                                <h2 className="h4">
                                    {showSupplier() ? t('Meus Atendimentos') : t('Fornecedores')}
                                </h2>
                            </div>
                            <div className="btn-toolbar mb-2 mb-md-0">
                                <div className="d-inline-flex align-items-center me-2 mb-3">
                                    <button
                                        className="btn btn-white animate-up-2 me-1" data-bs-toggle="modal" data-bs-target="#modalOrder">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="icon icon-xs">
                                            <path fillRule="evenodd" d="M14.615 1.595a.75.75 0 0 1 .359.852L12.982 9.75h7.268a.75.75 0 0 1 .548 1.262l-10.5 11.25a.75.75 0 0 1-1.272-.71l1.992-7.302H3.75a.75.75 0 0 1-.548-1.262l10.5-11.25a.75.75 0 0 1 .913-.143Z" clipRule="evenodd" />
                                        </svg>
                                    </button>
                                    {isType2Visible
                                        ? (
                                            <React.Fragment>
                                                <button id="starSupplier" className="btn btn-white animate-up-2 me-1" data-bs-toggle="modal" data-bs-target="#modalResp" onClick={starSupplier}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="icon icon-xs me-0">
                                                        <path fillRule="evenodd" d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z" clipRule="evenodd" />
                                                    </svg>
                                                </button>
                                                <button
                                                    className="btn btn-white animate-up-2 me-1"
                                                    onClick={toggleMarket}
                                                >
                                                    {showSupplier() ? (
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" strokeWidth="1.0" className="icon icon-xs me-0">
                                                    <path fillRule="evenodd" d="M15.97 2.47a.75.75 0 0 1 1.06 0l4.5 4.5a.75.75 0 0 1 0 1.06l-4.5 4.5a.75.75 0 1 1-1.06-1.06l3.22-3.22H7.5a.75.75 0 0 1 0-1.5h11.69l-3.22-3.22a.75.75 0 0 1 0-1.06Zm-7.94 9a.75.75 0 0 1 0 1.06l-3.22 3.22H16.5a.75.75 0 0 1 0 1.5H4.81l3.22 3.22a.75.75 0 1 1-1.06 1.06l-4.5-4.5a.75.75 0 0 1 0-1.06l4.5-4.5a.75.75 0 0 1 1.06 0Z" clipRule="evenodd" />
                                                </svg>
                                            ) : (
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" strokeWidth="1.0" className="icon icon-xs me-0">
                                                    <path fillRule="evenodd" d="M15.97 2.47a.75.75 0 0 1 1.06 0l4.5 4.5a.75.75 0 0 1 0 1.06l-4.5 4.5a.75.75 0 1 1-1.06-1.06l3.22-3.22H7.5a.75.75 0 0 1 0-1.5h11.69l-3.22-3.22a.75.75 0 0 1 0-1.06Zm-7.94 9a.75.75 0 0 1 0 1.06l-3.22 3.22H16.5a.75.75 0 0 1 0 1.5H4.81l3.22 3.22a.75.75 0 1 1-1.06 1.06l-4.5-4.5a.75.75 0 0 1 0-1.06l4.5-4.5a.75.75 0 0 1 1.06 0Z" clipRule="evenodd" />
                                                </svg>
                                            )}
                                                </button>
                                            </React.Fragment>
                                        ) : <React.Fragment></React.Fragment>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="card card-body border-0 shadow table-wrapper table-responsive" style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
                                    {selectedSupplier && validateSuppliers  && !suppliersWinner ? (
                                        <>
                                            <button
                                                className="btn btn-primary mb-4"
                                                onClick={() => setSelectedSupplier(null)}
                                            >
                                                {t('Voltar')}
                                            </button>
                                            <Supplier suppliers={selectedSupplier} onAllowOrder={onAllowOrderSubmit} onDenyOrder={onDenyOrderSubmit} />
                                        </>
                                    ) : (
                                        <table className="table table-hover" style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
                                            <thead style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
                                                <tr style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
                                                    <th className="border-gray-200" style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>{t('CATEGORIA')}</th>
                                                    <th className="border-gray-200" style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>{t('ESTADO')}</th>
                                                    <th className="border-gray-200" style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>{t('DETALHES')}</th>
                                                </tr>
                                            </thead>
                                            <tbody style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
                                                {!isLoading && suppliers && suppliers.length
                                                    ? suppliers.map(item =>
                                                        item.type === '2'
                                                            ? <React.Fragment key={item.id}></React.Fragment>
                                                            : !item.activesf && parseFloat(id) !== parseFloat(item.userId)
                                                                ? <React.Fragment key={item.id}></React.Fragment>
                                                                : parseFloat(id) !== parseFloat(item.userId)
                                                                    ? (<SuppliersRow key={item.id} data={item} myType={myType} onClick={() => handleSupplierClick(item.id)} onViewSupplier={onViewSupplier} onStarClick={onStarClick} />)
                                                                    : !supplier
                                                                        ? (<SuppliersRow key={item.id} data={item} myType={myType} onClick={() => handleSupplierClick(item.id)} onViewSupplier={onViewSupplier} onStarClick={onStarClick} />)
                                                                        : <React.Fragment></React.Fragment>)
                                                    : <tr><td colSpan={6}>{t('Carregando')}...</td></tr>
                                                }
                                            </tbody>
                                        </table>
                                    )}
                                </div>
                            </div>
                        </div>
                        <Pagination count={count} size={PAGE_SIZE} />
                        <Footer />
                    </main>
                </React.Fragment>
            )}
            <ViewRespModal data={viewComments} />
            <ViewSuppliersModal isDisabled={isDisabled} data={viewSupplier} supplier={supplier} onCancel={onCancelSubmit} onExclude={onExcludeSubmit} onProposta={onPropostaSubmit} onFinishOrder={onFinishSubmit} />
            <PrivacyModal />
            <TermsModal />
            <NewOrderModal onEnviou={onOrderSubmit} />
            <Toast type={notification.type} text={notification.text} />
        </React.Fragment>
    );
}

export default Suppliers;